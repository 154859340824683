import { fakeDeal } from "./dealFaker.js";

export const mockDeals = [
  // draft deals
  fakeDeal({
    state: "draft",
    dealBeneficiaries: ["Eric Cartman", "Towelie Towel", "Stan Marsh"],
    updatedAt: new Date()
  }),
  fakeDeal({
    state: "draft",
    dealBeneficiaries: ["Randy Marsh"],
    updatedAt: new Date("1995-12-17T03:24:00")
  }),
  fakeDeal({
    state: "draft",
    dealBeneficiaries: ["Kenny McCormick"],
    updatedAt: new Date("2020-12-17T03:24:00")
  }),
  fakeDeal({
    state: "draft",
    dealBeneficiaries: ["Kyle Broflovski"],
    billingPersonName: "Aaron Carter"
  }),
  fakeDeal({ state: "draft", dealBeneficiaries: ["Chef Hayes"] }),
  fakeDeal({ state: "draft", dealBeneficiaries: ["Liane Cartman"] }),
  fakeDeal({ state: "draft", dealBeneficiaries: ["Mr Slave"] }),
  fakeDeal({ state: "draft", dealBeneficiaries: ["Linda Scotch"] }),
  fakeDeal({ state: "draft", dealBeneficiaries: ["Butters Scotch"] }),
  fakeDeal({
    state: "draft",
    dealBeneficiaries: ["Butters Scotch"],
    billingPersonName: "Zz Top"
  }),
  fakeDeal({ state: "draft", dealBeneficiaries: ["Token Black", "Bono"] }),
  fakeDeal({ state: "draft", dealBeneficiaries: ["Aaalphonse Mephesto"] }),
  fakeDeal({ state: "draft", dealBeneficiaries: ["Zz Top"] }),
  ...[...Array(15)].map(() => fakeDeal({ state: "draft" })),
  // payment pending
  fakeDeal({
    state: "submitted",
    dealBeneficiaries: ["Jarvis Witte"],
    updatedAt: Date.now()
  }),
  ...[...Array(15)].map(() => fakeDeal({ state: "submitted" })),
  // payment in progress
  fakeDeal({
    state: "active",
    dealBeneficiaries: ["Tiger Woods"],
    updatedAt: new Date()
  }),
  ...[...Array(15)].map(() => fakeDeal({ state: "active" })),
  // fully paid
  ...[...Array(15)].map(() => fakeDeal({ state: "complete" })),
  // archived
  ...[...Array(15)].map(() => fakeDeal({ archived: true }))
];
