// Utility method for turning server values into human readable text

import moment from "moment";

export const readableValue = (value, type) => {
  let readable = value.toString();

  switch (true) {
    case value === "true" || value === true:
      return "Yes";
    case value === "false" || value === false:
      return "No";
    case value === "":
      return "Unknown";
    case type === "usd":
      readable = (+value).toFixed(2).replace(/\.0+$/, "");
      readable = `$${readable.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      return readable;
    case type === "date":
      return moment(value, "YYYY-MM-DD").format("MM/DD/YYYY");
    default:
      return value;
  }
};
