export const mockPetitions = [
  // most recently created petition
  {
    id: "4b296ba7-e725-5736-b402-50f4d15b1ac7",
    beneficiaryName: "Marge Simpson",
    petitioner: { legalName: "Big Company Inc", displayName: "Bigco" },
    assignee: "super.attorney@legalpad.io",
    visaType: "EB-2",
    createdAt: "April 29, 2020",
    updatedAt: ["April 26, 2020", "one day"],
    state: "active",
    archived: false
  },
  // most recently updated assigned petition
  {
    id: "7b0ac662-e948-58ed-9d96-6bc489c2d1a0",
    beneficiaryName: "Bart Simpson",
    petitioner: { legalName: null, displayName: "Duff Beer Company" },
    assignee: "Legal Assistant 1",
    visaType: "O-1",
    createdAt: "April 20, 2020",
    updatedAt: ["April 30, 2020", "one day"],
    state: "active",
    archived: false
  },
  {
    id: "4f3d0851-71af-5fff-8eb6-387daeeac023",
    beneficiaryName: "Montgomery Burns",
    petitioner: {
      legalName: "Springfield Nuclear Power Plant",
      displayName: "SNPP"
    },
    assignee: "legal.assistant@legalpad.io",
    visaType: "O-1",
    createdAt: "April 27, 2020",
    updatedAt: ["January 10, 2020", "one day"],
    state: "active",
    archived: false
  },
  {
    id: "69509819-7531-5bfd-bead-d29126dcefe4",
    beneficiaryName: "Ned Flanders",
    petitioner: { legalName: "Global Love Coalition", displayName: null },
    assignee: "legal.assistant@legalpad.io",
    visaType: "O-1",
    createdAt: "April 27, 2020",
    updatedAt: ["March 20, 2018", "one day"],
    state: "active",
    archived: false
  },
  {
    id: "6f012bef-7de4-5832-b080-ef3eba2e547e",
    beneficiaryName: "Apu Nahasapeemapetilon",
    petitioner: { legalName: "Quickee Mart", displayName: "" },
    assignee: "Legal Assistant 1",
    visaType: "O-1",
    createdAt: "April 27, 2016",
    updatedAt: ["April 27, 2020", "one day"],
    state: "active",
    archived: false
  },
  // most recently updated unassigned petition
  {
    id: "7f06f7b8-54a5-54bb-92c8-2e1d3b0ba4f5",
    beneficiaryName: "Homer Simpson",
    petitioner: "Springfield Nuclear Power Plant",
    assignee: null,
    visaType: "EB-2",
    createdAt: "April 30, 2020",
    updatedAt: ["April 27, 2020", "one day"],
    state: "active",
    archived: false
  },
  {
    id: "79d8e67c-c39a-5c1e-9396-ea5c1cf70bab",
    beneficiaryName: "Barney Gumble",
    petitioner: { legalName: "Duff Beer Company", displayName: "DBC" },
    assignee: null,
    visaType: "O-1",
    createdAt: "April 1, 2019",
    updatedAt: ["April 1, 2019", "one day"],
    state: "active",
    archived: false
  },
  // most recently updated archived petition
  {
    id: "7f5a5054-8d93-58fb-bfe7-c673371a2c8b",
    beneficiaryName: "Seymour Skinner",
    petitioner: "Self Petitioning",
    assignee: "wiley@legalpad.io",
    visaType: "H-1B",
    createdAt: "April 30, 2020",
    updatedAt: ["April 30, 2020", "one day"],
    state: "pending",
    archived: true
  }
];
