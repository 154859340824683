export function authenticityToken() {
  let token = "";
  const tags = document.getElementsByTagName("meta");

  for (let i = 0; i < tags.length; i++) {
    if (tags[i].name === "csrf-token") {
      token = tags[i].content;
      break;
    }
  }
  return token;
}

export const formHeaders = () => {
  const data = {
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-Token": authenticityToken(),
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  return data;
};
