const special = [
  "zeroth",
  "first",
  "second",
  "third",
  "fourth",
  "fifth",
  "sixth",
  "seventh",
  "eighth",
  "ninth",
  "tenth",
  "eleventh",
  "twelfth",
  "thirteenth",
  "fourteenth",
  "fifteenth",
  "sixteenth",
  "seventeenth",
  "eighteenth",
  "nineteenth"
];
const deca = [
  "twent",
  "thirt",
  "fort",
  "fift",
  "sixt",
  "sevent",
  "eight",
  "ninet"
];

export function stringifyNumber(n) {
  if (n < 20) return special[n];
  if (n % 10 === 0) return `${deca[Math.floor(n / 10) - 2]}ieth`;
  return `${deca[Math.floor(n / 10) - 2]}y-${special[n % 10]}`;
}

export function formatCentsToCurrency(cents) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  });

  return formatter.format(cents / 100);
}
