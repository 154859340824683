import faker from "faker";
import moment from "moment";

export function fakeDeal({
  state = "draft",
  submittedAt = null,
  updatedAt,
  dealBeneficiaries = [...Array(3)].map(() => faker.name.findName()),
  billingPersonName,
  installments = 3,
  archived = false
} = {}) {
  const billingPersonFirstName = faker.name.firstName();
  const billingPersonLastName = faker.name.lastName();

  const initCreatedAt = faker.date.past();

  return {
    id: faker.random.uuid(),
    name: faker.random.words(3),
    state,
    createdAt: initCreatedAt,
    updatedAt: updatedAt ?? initCreatedAt,
    submittedAt,
    finalPriceData: {
      priceTotal: null,
      discountsTotal: null,
      payTotal: null,
      dealDiscounts: null
    },
    updatedAtHumanized: updatedAt ? moment(updatedAt).fromNow() : null,
    author: {
      fullName: `${faker.name.firstName()} ${faker.name.lastName()}`
    },
    billingPerson: {
      id: faker.random.uuid(),
      fullName:
        billingPersonName ??
        `${billingPersonFirstName} ${billingPersonLastName}`,
      firstName: billingPersonFirstName,
      lastName: billingPersonFirstName,
      email: faker.internet.email()
    },
    organization: {
      id: faker.random.uuid(),
      displayName: faker.company.companyName(),
      isSelfPetitioning: false,
      isNotYetEstablished: false
    },
    dealBeneficiaries,
    petitionBundles: [
      {
        id: faker.random.uuid(),
        additionalServiceIds: [],
        finalPriceData: {
          priceTotal: null,
          discountsTotal: null,
          payTotal: null,
          dealDiscounts: null
        },
        petition: {
          id: null,
          workflow: {
            id: null,
            name: null
          },
          state: null,
          beneficiary: {
            id: null,
            firstName: null,
            lastName: null,
            email: null
          },
          representative: {
            id: null,
            firstName: null,
            lastName: null,
            email: null
          }
        }
      }
    ],
    dealDiscounts: [],
    installments,
    archived
  };
}
