/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
export function isHexColor(string) {
  return /^#([0-9A-F]{3}){1,2}$/i.test(string);
}

export function isColorString(string) {
  // Checks color name support in browser
  const s = new Option().style;
  s.color = string;
  return s.color === string;
}

export function getHexColor(string) {
  const temp = document.createElement("div");
  temp.style.color = string;
  const colors = window
    .getComputedStyle(document.body.appendChild(temp))
    .color.match(/\d+/g)
    .map(function(a) {
      return parseInt(a, 10);
    });
  document.body.removeChild(temp);
  return colors.length >= 3
    ? `#${((1 << 24) + (colors[0] << 16) + (colors[1] << 8) + colors[2])
        .toString(16)
        .substr(1)}`
    : false;
}

export function adjustColor(color, percent) {
  if (isColorString(color)) {
    color = getHexColor(color);
  }

  if (isHexColor(color)) {
    color = color.replace(/^.*#/, "");
    const num = parseInt(color, 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) + amt;
    const B = ((num >> 8) & 0x00ff) + amt;
    const G = (num & 0x0000ff) + amt;

    let ret = (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1);
    ret = `#${ret}`;
    return ret;
  }

  return color;
}
