import { formHeaders } from "./form";

export async function fetchCall(url, options) {
  const defaultOptions = {
    method: "GET",
    body: {},
    timeout: 3500,
    headers: formHeaders(),
    returnRawResponse: false
  };

  // Combine default options with options in argument
  const fetchOptions = { ...defaultOptions, ...options };

  // Begin building the object to pass to the fetch call
  let fetchObject = { ...fetchOptions, headers: fetchOptions.headers };

  // If method is specified and is not a GET request, add the body to the object
  if (fetchOptions.method && fetchOptions.method.toLowerCase() !== "get") {
    fetchObject = { ...fetchObject, body: options.body };
  }

  let obj = {};

  try {
    const res = await fetch(url, fetchObject);
    if (fetchOptions.returnRawResponse) {
      return res;
    }
    const json = await res.json();
    obj = {
      data: json,
      success: true
    };
    return obj;
  } catch (error) {
    obj = {
      data: null,
      success: false,
      error
    };
    return obj;
  }
}
