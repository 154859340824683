export const mockOrganizationItems = {
  items: [
    {
      id: "9a069d8c-5fd1-5a14-b3b3-d40f53b50617",
      legal_name: "Big Company Inc",
      to_s: "Bigco"
    },
    {
      id: "86b9b21a-e277-549e-a9a5-a74b7fc440fe",
      legal_name: "Little Company Inc",
      to_s: "Littleco"
    },
    {
      id: "31a6288a-282b-4238-a46b-fbb8b113abca",
      legal_name: null,
      to_s: "New "
    },
    {
      id: "163b9a3a-18a0-5fb7-9e20-15e4c7902bd1",
      legal_name: "Shinehardt Wig Company",
      to_s: "Shinehardt"
    }
  ]
};

export const mockWorkflows = {
  items: [
    {
      id: "ff3c633d-a235-5fc4-ac93-37085e294e24",
      name: "Cap",
      visa_type: "H-1B"
    },
    {
      id: "c1f1a6eb-042b-5b1c-b6bc-e5ff91fb00eb",
      name: "Startup Founder",
      visa_type: "O-1"
    },
    {
      id: "db1ac6c4-80ee-55fb-92b0-aaef0ed2ad06",
      name: "Startup Founder EB",
      visa_type: "EB-1"
    },
    {
      id: "33a6088c-a991-5df0-ac45-6bb7d25b8a13",
      name: "Startup Founder EB",
      visa_type: "EB-2"
    },
    {
      id: "509f630a-a6be-5d69-816d-60f20b5ad8d0",
      name: "TN Workflow",
      visa_type: "Other"
    }
  ]
};

export const mockOrganizationPeople = [
  {
    id: "7eaf18e1-3df4-4e3a-8b04-76ad928db589",
    firstName: "Homer",
    lastName: "Simpson",
    email: "homer.simpson@hey.com"
  },
  {
    id: "5282c63a-f022-4014-b4f6-88e16f81e738",
    firstName: "Bart",
    lastName: "Simpson",
    email: "bart.simpson@mail.com"
  },
  {
    id: "1b110ec2-8ed6-458a-b31f-477e7db69e81",
    firstName: "Barney",
    lastName: "Gumble",
    email: "barney@hey.com"
  },
  {
    id: "ae44bba7-0224-404e-833b-663a8050f48c",
    firstName: "Apu",
    lastName: "Nahasapeemapetilon",
    email: "apu@quickeemart.com"
  },
  {
    id: "259519ad-c15b-4e44-be3b-868ec4997136",
    firstName: "Krusty",
    lastName: "The Clown",
    email: "krusty@hey.com"
  },
  {
    id: "c9649337-353c-4c59-9f97-d5f715aad621",
    firstName: "Ned",
    lastName: "Flanders",
    email: "ned@springfieldpower.com"
  }
];

export const mockPetitionerStepDeal = {
  id: "c636e9ed-bba5-42fc-9d9b-1f2a1f4803c1",
  name: null,
  billingPerson: {
    id: "7eaf18e1-3df4-4e3a-8b04-76ad928db589",
    firstName: "Jiminy",
    lastName: "Cricket",
    email: "jcrick@hey.com"
  },
  organization: {
    id: "163b9a3a-18a0-5fb7-9e20-15e4c7902bd1",
    displayName: "Shinehardt"
  },
  petitionBundles: []
};

export const mockWorkflowsWithFees = [
  {
    id: "16185c81-22b0-4375-983a-6be801f10ee7",
    name: "B-1",
    visaType: "Other",
    toS: "Other - B-1",
    baseFees: [{ name: "Service Fee", amount: 150000 }],
    additionalServices: [
      {
        id: "24e2c6f0-45ff-4d26-8096-a2eae83862d3",
        name: "ACWIA 26+ FTE Fee",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "14162e0b-a656-5770-891e-6d01d204a331",
        name: "Additional press",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "a1e51626-150f-509a-a706-829b8a3bff7b",
        name: "Guaranteed approval",
        amount: 150000,
        isDefaultOption: false
      },
      {
        id: "0871042e-fa16-5cc6-a31b-dbb926024364",
        name: "Premium processing",
        amount: 144000,
        isDefaultOption: false
      }
    ],
    totalBaseFees: 150000,
    url:
      "http://localhost:3000/workflows/16185c81-22b0-4375-983a-6be801f10ee7.json"
  },
  {
    id: "9b8646d5-369f-4589-b64a-ce6d117525a6",
    name: "B-2",
    visaType: "Other",
    toS: "Other - B-2",
    baseFees: [{ name: "Service Fee", amount: 80000 }],
    additionalServices: [
      {
        id: "24e2c6f0-45ff-4d26-8096-a2eae83862d3",
        name: "ACWIA 26+ FTE Fee",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "14162e0b-a656-5770-891e-6d01d204a331",
        name: "Additional press",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "a1e51626-150f-509a-a706-829b8a3bff7b",
        name: "Guaranteed approval",
        amount: 150000,
        isDefaultOption: false
      },
      {
        id: "0871042e-fa16-5cc6-a31b-dbb926024364",
        name: "Premium processing",
        amount: 144000,
        isDefaultOption: false
      }
    ],
    totalBaseFees: 80000,
    url:
      "http://localhost:3000/workflows/9b8646d5-369f-4589-b64a-ce6d117525a6.json"
  },
  {
    id: "190546a1-ed6c-4b97-8c40-1f79647a8abb",
    name: "E-2",
    visaType: "Other",
    toS: "Other - E-2",
    baseFees: [{ name: "Service Fee", amount: 800000 }],
    additionalServices: [
      {
        id: "24e2c6f0-45ff-4d26-8096-a2eae83862d3",
        name: "ACWIA 26+ FTE Fee",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "14162e0b-a656-5770-891e-6d01d204a331",
        name: "Additional press",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "a1e51626-150f-509a-a706-829b8a3bff7b",
        name: "Guaranteed approval",
        amount: 150000,
        isDefaultOption: false
      },
      {
        id: "0871042e-fa16-5cc6-a31b-dbb926024364",
        name: "Premium processing",
        amount: 144000,
        isDefaultOption: false
      }
    ],
    totalBaseFees: 800000,
    url:
      "http://localhost:3000/workflows/190546a1-ed6c-4b97-8c40-1f79647a8abb.json"
  },
  {
    id: "49f54555-d090-47fb-b77a-27cc29d7bcf0",
    name: "E-3",
    visaType: "Other",
    toS: "Other - E-3",
    baseFees: [{ name: "Service Fee", amount: 380000 }],
    additionalServices: [
      {
        id: "24e2c6f0-45ff-4d26-8096-a2eae83862d3",
        name: "ACWIA 26+ FTE Fee",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "14162e0b-a656-5770-891e-6d01d204a331",
        name: "Additional press",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "a1e51626-150f-509a-a706-829b8a3bff7b",
        name: "Guaranteed approval",
        amount: 150000,
        isDefaultOption: false
      },
      {
        id: "0871042e-fa16-5cc6-a31b-dbb926024364",
        name: "Premium processing",
        amount: 144000,
        isDefaultOption: false
      }
    ],
    totalBaseFees: 380000,
    url:
      "http://localhost:3000/workflows/49f54555-d090-47fb-b77a-27cc29d7bcf0.json"
  },
  {
    id: "db1ac6c4-80ee-55fb-92b0-aaef0ed2ad06",
    name: "EB-1A",
    visaType: "EB-1",
    toS: "EB-1 - EB-1A",
    baseFees: [
      { name: "Service Fee", amount: 1000000 },
      { name: "Government Filing Fees", amount: 70000 }
    ],
    additionalServices: [
      {
        id: "24e2c6f0-45ff-4d26-8096-a2eae83862d3",
        name: "ACWIA 26+ FTE Fee",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "14162e0b-a656-5770-891e-6d01d204a331",
        name: "Additional press",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "a1e51626-150f-509a-a706-829b8a3bff7b",
        name: "Guaranteed approval",
        amount: 150000,
        isDefaultOption: false
      },
      {
        id: "0871042e-fa16-5cc6-a31b-dbb926024364",
        name: "Premium processing",
        amount: 144000,
        isDefaultOption: false
      }
    ],
    totalBaseFees: 1070000,
    url:
      "http://localhost:3000/workflows/db1ac6c4-80ee-55fb-92b0-aaef0ed2ad06.json"
  },
  {
    id: "9e979f22-4062-4b3e-9e35-280c2f14e1b5",
    name: "EB-2 NIW",
    visaType: "EB-2",
    toS: "EB-2 - EB-2 NIW",
    baseFees: [
      { name: "Service Fee", amount: 1200000 },
      { name: "Government Filing Fees", amount: 70000 }
    ],
    additionalServices: [
      {
        id: "24e2c6f0-45ff-4d26-8096-a2eae83862d3",
        name: "ACWIA 26+ FTE Fee",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "14162e0b-a656-5770-891e-6d01d204a331",
        name: "Additional press",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "a1e51626-150f-509a-a706-829b8a3bff7b",
        name: "Guaranteed approval",
        amount: 150000,
        isDefaultOption: false
      },
      {
        id: "0871042e-fa16-5cc6-a31b-dbb926024364",
        name: "Premium processing",
        amount: 144000,
        isDefaultOption: false
      }
    ],
    totalBaseFees: 1270000,
    url:
      "http://localhost:3000/workflows/9e979f22-4062-4b3e-9e35-280c2f14e1b5.json"
  },
  {
    id: "33a6088c-a991-5df0-ac45-6bb7d25b8a13",
    name: "EB-2 PERM",
    visaType: "EB-2",
    toS: "EB-2 - EB-2 PERM",
    baseFees: [
      { name: "Service Fee", amount: 1400000 },
      { name: "Government Filing Fees", amount: 70000 }
    ],
    additionalServices: [
      {
        id: "24e2c6f0-45ff-4d26-8096-a2eae83862d3",
        name: "ACWIA 26+ FTE Fee",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "14162e0b-a656-5770-891e-6d01d204a331",
        name: "Additional press",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "a1e51626-150f-509a-a706-829b8a3bff7b",
        name: "Guaranteed approval",
        amount: 150000,
        isDefaultOption: false
      },
      {
        id: "0871042e-fa16-5cc6-a31b-dbb926024364",
        name: "Premium processing",
        amount: 144000,
        isDefaultOption: false
      }
    ],
    totalBaseFees: 1470000,
    url:
      "http://localhost:3000/workflows/33a6088c-a991-5df0-ac45-6bb7d25b8a13.json"
  },
  {
    id: "ff3c633d-a235-5fc4-ac93-37085e294e24",
    name: "H-1B",
    visaType: "H-1B",
    toS: "H-1B - H-1B",
    baseFees: [
      { name: "Service Fee", amount: 380000 },
      { name: "Government Filing Fees", amount: 46000 },
      { name: "Anti Fraud", amount: 50000 },
      { name: "ACWIA", amount: 75000 }
    ],
    additionalServices: [
      {
        id: "24e2c6f0-45ff-4d26-8096-a2eae83862d3",
        name: "ACWIA 26+ FTE Fee",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "14162e0b-a656-5770-891e-6d01d204a331",
        name: "Additional press",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "a1e51626-150f-509a-a706-829b8a3bff7b",
        name: "Guaranteed approval",
        amount: 150000,
        isDefaultOption: false
      },
      {
        id: "0871042e-fa16-5cc6-a31b-dbb926024364",
        name: "Premium processing",
        amount: 144000,
        isDefaultOption: false
      }
    ],
    totalBaseFees: 551000,
    url:
      "http://localhost:3000/workflows/ff3c633d-a235-5fc4-ac93-37085e294e24.json"
  },
  {
    id: "76f3317b-08d3-4afe-b222-93abb5f76d68",
    name: "H-1B Transfer",
    visaType: "H-1B",
    toS: "H-1B - H-1B Transfer",
    baseFees: [
      { name: "Service Fee", amount: 380000 },
      { name: "Government Filing Fees", amount: 46000 },
      { name: "Anti Fraud", amount: 50000 },
      { name: "ACWIA", amount: 75000 }
    ],
    additionalServices: [
      {
        id: "24e2c6f0-45ff-4d26-8096-a2eae83862d3",
        name: "ACWIA 26+ FTE Fee",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "14162e0b-a656-5770-891e-6d01d204a331",
        name: "Additional press",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "a1e51626-150f-509a-a706-829b8a3bff7b",
        name: "Guaranteed approval",
        amount: 150000,
        isDefaultOption: false
      },
      {
        id: "0871042e-fa16-5cc6-a31b-dbb926024364",
        name: "Premium processing",
        amount: 144000,
        isDefaultOption: false
      }
    ],
    totalBaseFees: 551000,
    url:
      "http://localhost:3000/workflows/76f3317b-08d3-4afe-b222-93abb5f76d68.json"
  },
  {
    id: "76ab42ad-0f09-41a1-9f3c-f33648cc5f4f",
    name: "J-1",
    visaType: "Other",
    toS: "Other - J-1",
    baseFees: [{ name: "Service Fee", amount: 300000 }],
    additionalServices: [
      {
        id: "24e2c6f0-45ff-4d26-8096-a2eae83862d3",
        name: "ACWIA 26+ FTE Fee",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "14162e0b-a656-5770-891e-6d01d204a331",
        name: "Additional press",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "a1e51626-150f-509a-a706-829b8a3bff7b",
        name: "Guaranteed approval",
        amount: 150000,
        isDefaultOption: false
      },
      {
        id: "0871042e-fa16-5cc6-a31b-dbb926024364",
        name: "Premium processing",
        amount: 144000,
        isDefaultOption: false
      }
    ],
    totalBaseFees: 300000,
    url:
      "http://localhost:3000/workflows/76ab42ad-0f09-41a1-9f3c-f33648cc5f4f.json"
  },
  {
    id: "680c5b71-f78d-47e5-82d9-ffa401b906c9",
    name: "L-1A",
    visaType: "Other",
    toS: "Other - L-1A",
    baseFees: [
      { name: "Service Fee", amount: 800000 },
      { name: "Government Filing Fees", amount: 46000 },
      { name: "Anti Fraud", amount: 50000 }
    ],
    additionalServices: [
      {
        id: "24e2c6f0-45ff-4d26-8096-a2eae83862d3",
        name: "ACWIA 26+ FTE Fee",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "14162e0b-a656-5770-891e-6d01d204a331",
        name: "Additional press",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "a1e51626-150f-509a-a706-829b8a3bff7b",
        name: "Guaranteed approval",
        amount: 150000,
        isDefaultOption: false
      },
      {
        id: "0871042e-fa16-5cc6-a31b-dbb926024364",
        name: "Premium processing",
        amount: 144000,
        isDefaultOption: false
      }
    ],
    totalBaseFees: 896000,
    url:
      "http://localhost:3000/workflows/680c5b71-f78d-47e5-82d9-ffa401b906c9.json"
  },
  {
    id: "3a427ce9-427d-4f8d-8666-c0f1ebab3fe5",
    name: "L-1B",
    visaType: "Other",
    toS: "Other - L-1B",
    baseFees: [
      { name: "Service Fee", amount: 800000 },
      { name: "Government Filing Fees", amount: 46000 },
      { name: "Anti Fraud", amount: 50000 }
    ],
    additionalServices: [
      {
        id: "24e2c6f0-45ff-4d26-8096-a2eae83862d3",
        name: "ACWIA 26+ FTE Fee",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "14162e0b-a656-5770-891e-6d01d204a331",
        name: "Additional press",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "a1e51626-150f-509a-a706-829b8a3bff7b",
        name: "Guaranteed approval",
        amount: 150000,
        isDefaultOption: false
      },
      {
        id: "0871042e-fa16-5cc6-a31b-dbb926024364",
        name: "Premium processing",
        amount: 144000,
        isDefaultOption: false
      }
    ],
    totalBaseFees: 896000,
    url:
      "http://localhost:3000/workflows/3a427ce9-427d-4f8d-8666-c0f1ebab3fe5.json"
  },
  {
    id: "c1f1a6eb-042b-5b1c-b6bc-e5ff91fb00eb",
    name: "O-1A",
    visaType: "O-1",
    toS: "O-1 - O-1A",
    baseFees: [
      { name: "Service Fee", amount: 950000 },
      { name: "Government Filing Fees", amount: 46000 }
    ],
    additionalServices: [
      {
        id: "24e2c6f0-45ff-4d26-8096-a2eae83862d3",
        name: "ACWIA 26+ FTE Fee",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "14162e0b-a656-5770-891e-6d01d204a331",
        name: "Additional press",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "a1e51626-150f-509a-a706-829b8a3bff7b",
        name: "Guaranteed approval",
        amount: 150000,
        isDefaultOption: false
      },
      {
        id: "0871042e-fa16-5cc6-a31b-dbb926024364",
        name: "Premium processing",
        amount: 144000,
        isDefaultOption: false
      }
    ],
    totalBaseFees: 996000,
    url:
      "http://localhost:3000/workflows/c1f1a6eb-042b-5b1c-b6bc-e5ff91fb00eb.json"
  },
  {
    id: "694b52ba-be8c-458b-8251-f7c35ed1aa77",
    name: "O-1A Renewal",
    visaType: "O-1",
    toS: "O-1 - O-1A Renewal",
    baseFees: [
      { name: "Service Fee", amount: 600000 },
      { name: "Government Filing Fees", amount: 46000 }
    ],
    additionalServices: [
      {
        id: "24e2c6f0-45ff-4d26-8096-a2eae83862d3",
        name: "ACWIA 26+ FTE Fee",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "14162e0b-a656-5770-891e-6d01d204a331",
        name: "Additional press",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "a1e51626-150f-509a-a706-829b8a3bff7b",
        name: "Guaranteed approval",
        amount: 150000,
        isDefaultOption: false
      },
      {
        id: "0871042e-fa16-5cc6-a31b-dbb926024364",
        name: "Premium processing",
        amount: 144000,
        isDefaultOption: false
      }
    ],
    totalBaseFees: 646000,
    url:
      "http://localhost:3000/workflows/694b52ba-be8c-458b-8251-f7c35ed1aa77.json"
  },
  {
    id: "7b4b319e-835a-5cce-840a-9f0ac8b6a32e",
    name: "TN",
    visaType: "Other",
    toS: "Other - TN",
    baseFees: [
      { name: "Service Fee", amount: 300000 },
      { name: "Government Filing Fees", amount: 46000 }
    ],
    additionalServices: [
      {
        id: "24e2c6f0-45ff-4d26-8096-a2eae83862d3",
        name: "ACWIA 26+ FTE Fee",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "14162e0b-a656-5770-891e-6d01d204a331",
        name: "Additional press",
        amount: 75000,
        isDefaultOption: false
      },
      {
        id: "a1e51626-150f-509a-a706-829b8a3bff7b",
        name: "Guaranteed approval",
        amount: 150000,
        isDefaultOption: false
      },
      {
        id: "0871042e-fa16-5cc6-a31b-dbb926024364",
        name: "Premium processing",
        amount: 144000,
        isDefaultOption: false
      }
    ],
    totalBaseFees: 346000,
    url:
      "http://localhost:3000/workflows/7b4b319e-835a-5cce-840a-9f0ac8b6a32e.json"
  }
];

export const mockPetitionBundles = [
  {
    id: "5b23f310-ad78-4311-bdfc-e02580489a67",
    fees: null,
    additionalServiceIds: [],
    petition: {
      id: "40d7db43-6538-48e9-8fdb-acbc45d5bf53",
      workflow: {
        id: "db1ac6c4-80ee-55fb-92b0-aaef0ed2ad06",
        visaType: "EB-1"
      },
      beneficiary: {
        id: "8df62d2e-d032-4f36-9d8a-e7ccec266444",
        firstName: "Bill ",
        lastName: "Gates",
        email: "bill.gates@microsoft.com"
      },
      representative: { id: null, firstName: null, lastName: null, email: null }
    }
  },
  {
    id: "d82b7997-3ae3-4886-918e-36eed156c5f3",
    fees: null,
    additionalServiceIds: [
      "a1e51626-150f-509a-a706-829b8a3bff7b",
      "14162e0b-a656-5770-891e-6d01d204a331"
    ],
    petition: {
      id: "31717933-68aa-4df8-b2c6-eacd5f953281",
      workflow: {
        id: "ff3c633d-a235-5fc4-ac93-37085e294e24",
        visaType: "H-1B"
      },
      beneficiary: {
        id: "6e968eef-e50b-4c84-b9aa-43aee6e7fda3",
        firstName: "Tania",
        lastName: "Perlman",
        email: "tania.perlman@microsoft.com"
      },
      representative: { id: null, firstName: null, lastName: null, email: null }
    }
  }
];

export const fullDealMock = {
  id: "6a4e4971-ed62-4894-b816-d99caed3a242",
  name: null,
  billingPerson: {
    id: "5ebb28f8-64a2-48cb-a6f0-e4cf3a9fcb81",
    firstName: "John",
    lastName: "Deer",
    email: "jdeer@gmail.com"
  },
  organization: {
    id: "9a069d8c-5fd1-5a14-b3b3-d40f53b50617",
    displayName: "Bigco",
    isSelfPetitioning: false,
    isNotYetEstablished: false
  },
  petitionBundles: [
    {
      id: "5a0e674b-e7d9-485f-9e5c-f83d86ec5227",
      fees: null,
      additionalServiceIds: [
        "0871042e-fa16-5cc6-a31b-dbb926024364",
        "a1e51626-150f-509a-a706-829b8a3bff7b",
        "14162e0b-a656-5770-891e-6d01d204a331"
      ],
      petition: {
        id: "f63dfd21-81a0-4d27-85c5-557609f5f073",
        workflow: {
          id: "190546a1-ed6c-4b97-8c40-1f79647a8abb",
          name: "E-2"
        },
        state: "pending",
        beneficiary: {
          id: "5ebb28f8-64a2-48cb-a6f0-e4cf3a9fcb81",
          firstName: "John",
          lastName: "Deer",
          email: "jdeer@gmail.com"
        },
        representative: {
          id: "5ebb28f8-64a2-48cb-a6f0-e4cf3a9fcb81",
          firstName: "John",
          lastName: "Deer",
          email: "jdeer@gmail.com"
        }
      }
    },
    {
      id: "02eb6182-bf27-4e3c-b4e6-2ff4c4d28ebe",
      fees: null,
      additionalServiceIds: [
        "0871042e-fa16-5cc6-a31b-dbb926024364",
        "a1e51626-150f-509a-a706-829b8a3bff7b",
        "14162e0b-a656-5770-891e-6d01d204a331"
      ],
      petition: {
        id: "293d572c-6a01-43bf-8b44-fe88579a5604",
        workflow: {
          id: "9b8646d5-369f-4589-b64a-ce6d117525a6",
          name: "B-2"
        },
        state: "pending",
        beneficiary: {
          id: "5ebb28f8-64a2-48cb-a6f0-e4cf3a9fcb81",
          firstName: "John",
          lastName: "Deer",
          email: "jdeer@gmail.com"
        },
        representative: {
          id: "722637a5-e111-41ef-8595-0eade41fa6ea",
          firstName: "Jamie",
          lastName: "Lannister",
          email: "jlanny@gmail.com"
        }
      }
    },
    {
      id: "573b2bc3-4d75-4481-a5b9-36440590f2f2",
      fees: null,
      additionalServiceIds: [
        "0871042e-fa16-5cc6-a31b-dbb926024364",
        "a1e51626-150f-509a-a706-829b8a3bff7b"
      ],
      petition: {
        id: "8d905c37-5819-47e2-92da-b93e13dc58fc",
        workflow: {
          id: "33a6088c-a991-5df0-ac45-6bb7d25b8a13",
          name: "EB-2 NIW"
        },
        state: "pending",
        beneficiary: {
          id: "722637a5-e111-41ef-8595-0eade41fa6ea",
          firstName: "Jamie",
          lastName: "Lannister",
          email: "jlanny@gmail.com"
        },
        representative: {
          id: "c1454c75-49d3-4232-abc2-52c68ce1dd02",
          firstName: "Khadia",
          lastName: "Kenya",
          email: "khadia@gmail.com"
        }
      }
    },
    {
      id: "f3391717-75ae-4af9-b645-10f6d97a4d5a",
      fees: null,
      additionalServiceIds: [
        "0871042e-fa16-5cc6-a31b-dbb926024364",
        "a1e51626-150f-509a-a706-829b8a3bff7b"
      ],
      petition: {
        id: "c1c6a702-bb45-48e9-ba48-decc2b00f5cc",
        workflow: {
          id: "16185c81-22b0-4375-983a-6be801f10ee7",
          name: "B-1"
        },
        state: "pending",
        beneficiary: {
          id: "c1454c75-49d3-4232-abc2-52c68ce1dd02",
          firstName: "Khadia",
          lastName: "Kenya",
          email: "khadia@gmail.com"
        },
        representative: {
          id: "722637a5-e111-41ef-8595-0eade41fa6ea",
          firstName: "Jamie",
          lastName: "Lannister",
          email: "jlanny@gmail.com"
        }
      }
    }
  ],
  dealDiscounts: [
    {
      id: "648d4fd9-8455-4af6-a5af-aa67893cced0",
      amount: 120000,
      name: "Techstars",
      discountCodeId: "a218d586-f646-5a53-ac4f-d1ed679b6329"
    }
  ],
  installments: 24
};

export const mockInstallmentPlans = [
  {
    months: 1,
    label: "Single Payment"
  },
  {
    months: 3,
    label: "3-month plan"
  },
  {
    months: 6,
    label: "6-month plan"
  },
  {
    months: 12,
    label: "12-month plan"
  },
  {
    months: 24,
    label: "24-month plan"
  },
  {
    months: 36,
    label: "36-month plan"
  }
];
