export const mockDeals = [
  {
    id: "830bd028-e61b-4e36-be6d-585a52cb89ca",
    name: null,
    state: "draft",
    createdAt: "2020-08-05T13:36:56.471-07:00",
    updatedAt: "2020-08-05T13:36:56.471-07:00",
    submittedAt: null,
    finalPriceData: {
      priceTotal: null,
      discountsTotal: null,
      payTotal: null,
      dealDiscounts: null
    },
    updatedAtHumanized: "about 1 month ago",
    author: {
      fullName: "Simpsons Fan"
    },
    billingPerson: {
      id: "e07ad668-c402-4ee6-a597-e387da0e00b0",
      fullName: "Ned Flanders",
      firstName: "Ned",
      lastName: "Flanders",
      email: "seth3@cbj.com"
    },
    organization: {
      id: "163b9a3a-18a0-5fb7-9e20-15e4c7902bd1",
      displayName: "Diddly Doodly bible co",
      isSelfPetitioning: false,
      isNotYetEstablished: false
    },
    dealBeneficiaries: [
      "Randy Marsh",
      "Stand In",
      "Stan Marsh",
      "Eric Cartman"
    ],
    petitionBundles: [
      {
        id: "dceeb541-a82e-4289-9d5d-8943421c8fd6",
        additionalServiceIds: [],
        finalPriceData: {
          priceTotal: null,
          discountsTotal: null,
          payTotal: null,
          dealDiscounts: null
        },
        petition: {
          id: null,
          workflow: {
            id: null,
            name: null
          },
          state: null,
          beneficiary: {
            id: null,
            firstName: null,
            lastName: null,
            email: null
          },
          representative: {
            id: null,
            firstName: null,
            lastName: null,
            email: null
          }
        }
      }
    ],
    dealDiscounts: [],
    installments: null,
    archived: false
  },
  {
    id: "b5fdc133-1447-4889-9182-44280f762b6e",
    name: null,
    state: "draft",
    createdAt: "2020-08-06T15:39:28.130-07:00",
    updatedAt: "2020-08-14T10:37:08.803-07:00",
    submittedAt: null,
    finalPriceData: {
      priceTotal: null,
      discountsTotal: null,
      payTotal: null,
      dealDiscounts: null
    },
    updatedAtHumanized: "24 days ago",
    author: {
      fullName: "Simpsons Fan"
    },
    billingPerson: {
      id: "6e62bd6a-7f6a-438c-aec2-f9e09366fe96",
      fullName: "Marge Simpson",
      firstName: "Marge",
      lastName: "Simpson",
      email: "marge@simpsons.com"
    },
    organization: {
      id: "86b9b21a-e277-549e-a9a5-a74b7fc440fe",
      displayName: "Springfield parents assoc",
      isSelfPetitioning: false,
      isNotYetEstablished: false
    },
    dealBeneficiaries: ["Some Dude", "Other Person", "Randy Marsh"],
    petitionBundles: [
      {
        id: "ad6fdde1-4979-4dc4-8c25-6d54b57f1375",
        additionalServiceIds: [
          "70050afb-99c0-4c8d-a2d1-ce35a9c0d314",
          "5e56a049-8419-4f6b-97f1-fd8a893b1614"
        ],
        finalPriceData: {},
        petition: {
          id: "f809b194-8cfe-40ff-b484-3e5bcf7ac94f",
          workflow: {
            id: "a41e46fd-e2a7-4ca1-8a68-ddef1eec2d36",
            name: "H-1B"
          },
          state: "pending",
          beneficiary: {
            id: "6e62bd6a-7f6a-438c-aec2-f9e09366fe96",
            firstName: "Mr",
            lastName: "Small",
            email: "mrsmall@man.com"
          },
          representative: {
            id: "8618dbbf-465c-47c6-ae4d-9fade952dc68",
            firstName: "Mr",
            lastName: "Big",
            email: "mrbig@yahoo.com"
          }
        }
      },
      {
        id: "880bd6d4-367b-4c49-a1cf-7ffa6dbddfc8",
        additionalServiceIds: [
          "5e56a049-8419-4f6b-97f1-fd8a893b1614",
          "70050afb-99c0-4c8d-a2d1-ce35a9c0d314"
        ],
        finalPriceData: {},
        petition: {
          id: "f1c1bd15-1e36-4651-ad72-29227d7a1e56",
          workflow: {
            id: "197c7dad-7566-44f0-94f0-21e04ae16ff7",
            name: "B-2"
          },
          state: "pending",
          beneficiary: {
            id: "8618dbbf-465c-47c6-ae4d-9fade952dc68",
            firstName: "Mr",
            lastName: "Big",
            email: "mrbig@yahoo.com"
          },
          representative: {
            id: "8618dbbf-465c-47c6-ae4d-9fade952dc68",
            firstName: "Mr",
            lastName: "Big",
            email: "mrbig@yahoo.com"
          }
        }
      }
    ],
    dealDiscounts: [
      {
        id: "5d185099-e8eb-4750-8dbb-07b47abf35d5",
        amount: 120000,
        name: "Techstars",
        discountCodeId: "a218d586-f646-5a53-ac4f-d1ed679b6329"
      }
    ],
    installments: 12,
    archived: false
  },
  {
    id: "65d538cf-3519-4d9e-a1c7-d534b742f8d8",
    name: null,
    state: "submitted",
    createdAt: "2020-08-23T16:43:56.328-07:00",
    updatedAt: "2020-08-23T16:46:00.670-07:00",
    submittedAt: "2020-08-23T16:46:00.714-07:00",
    finalPriceData: {
      price_total: 1071000,
      discounts_total: 77300,
      pay_total: 993700,
      petition_bundles: [
        {
          id: "e43ffdba-a50b-4457-b5f8-e6fc9272359a",
          price_total: 1071000,
          base_fees: [
            {
              name: "Service Fee",
              amount: 800000
            },
            {
              name: "Government Filing Fees",
              amount: 46000
            }
          ],
          additional_services: [
            {
              id: "5e56a049-8419-4f6b-97f1-fd8a893b1614",
              amount: 150000,
              name: "Guaranteed approval"
            },
            {
              id: "3ed926d8-b3aa-4cb6-bc31-856a45f864a8",
              amount: 75000,
              name: "Additional press"
            }
          ]
        }
      ],
      deal_discounts: [
        {
          id: "8d47d255-61b4-47f5-9181-5ea1b407ab18",
          name: "Multiple Founder",
          amount: 75000,
          discount_code_id: "06feeb8e-d8ce-500e-98cc-31298c138afc"
        },
        {
          id: "5acdcfb6-209a-473b-bb33-dcd4aabf0ef8",
          name: "Jordan",
          amount: 2300,
          discount_code_id: null
        }
      ],
      priceTotal: 993700,
      discountsTotal: 77300,
      payTotal: 993700,
      dealDiscounts: [
        {
          id: "8d47d255-61b4-47f5-9181-5ea1b407ab18",
          name: "Multiple Founder",
          amount: 75000,
          discount_code_id: "06feeb8e-d8ce-500e-98cc-31298c138afc"
        },
        {
          id: "5acdcfb6-209a-473b-bb33-dcd4aabf0ef8",
          name: "Jordan",
          amount: 2300,
          discount_code_id: null
        }
      ]
    },
    updatedAtHumanized: "15 days ago",
    author: {
      fullName: "Flintstones Diehard"
    },
    billingPerson: {
      id: "fba19ab0-7c4a-4bd1-920c-5625c253001e",
      fullName: "Barney Gumble",
      firstName: "Barney",
      lastName: "Gumble",
      email: "barney@flintstones.com"
    },
    organization: {
      id: "18bc13fa-0864-474e-9bfa-ba1839486c97",
      displayName: "Flintstone Co",
      isSelfPetitioning: false,
      isNotYetEstablished: false
    },
    dealBeneficiaries: ["Mona Lisa", "Claude Monet"],
    petitionBundles: [
      {
        id: "e43ffdba-a50b-4457-b5f8-e6fc9272359a",
        additionalServiceIds: [
          "5e56a049-8419-4f6b-97f1-fd8a893b1614",
          "3ed926d8-b3aa-4cb6-bc31-856a45f864a8"
        ],
        finalPriceData: {
          price_total: 1071000,
          base_fees: [
            {
              name: "Service Fee",
              amount: 800000
            },
            {
              name: "Government Filing Fees",
              amount: 46000
            }
          ],
          additional_services: [
            {
              id: "5e56a049-8419-4f6b-97f1-fd8a893b1614",
              amount: 150000,
              name: "Guaranteed approval"
            },
            {
              id: "3ed926d8-b3aa-4cb6-bc31-856a45f864a8",
              amount: 75000,
              name: "Additional press"
            }
          ]
        },
        petition: {
          id: "dab50180-d8e5-4cfd-b3c9-8af7635d72db",
          workflow: {
            id: "938edff2-74a2-4a02-8c48-9c1fe99f5366",
            name: "O-1A"
          },
          state: "pending",
          beneficiary: {
            id: "4c87e4bb-2898-4a46-9d5b-36bada7e3c95",
            firstName: "Edward ",
            lastName: "Abbey",
            email: "edabbey@moab.com"
          },
          representative: {
            id: "fba19ab0-7c4a-4bd1-920c-5625c253001e",
            firstName: "John",
            lastName: "Muir",
            email: "jmuir@hiker.com"
          }
        }
      }
    ],
    dealDiscounts: [
      {
        id: "8d47d255-61b4-47f5-9181-5ea1b407ab18",
        amount: 75000,
        name: "Multiple Founder",
        discountCodeId: "06feeb8e-d8ce-500e-98cc-31298c138afc"
      },
      {
        id: "5acdcfb6-209a-473b-bb33-dcd4aabf0ef8",
        amount: 2300,
        name: "Jordan",
        discountCodeId: null
      }
    ],
    installments: 6,
    archived: false
  },
  {
    id: "gh1231-1883-4368-a793-226ce3c66629",
    name: null,
    state: "active",
    createdAt: "2020-08-31T09:06:58.724-07:00",
    updatedAt: "2020-08-31T09:07:42.123-07:00",
    submittedAt: "2020-08-31T09:07:42.160-07:00",
    finalPriceData: {
      price_total: 1025000,
      discounts_total: 150000,
      pay_total: 875000,
      petition_bundles: [
        {
          id: "60f8c151-2704-44d4-ac4d-186f9c5b1619",
          price_total: 1025000,
          base_fees: [
            {
              name: "Service Fee",
              amount: 800000
            }
          ],
          additional_services: [
            {
              id: "5e56a049-8419-4f6b-97f1-fd8a893b1614",
              amount: 150000,
              name: "Guaranteed approval"
            },
            {
              id: "3ed926d8-b3aa-4cb6-bc31-856a45f864a8",
              amount: 75000,
              name: "Additional press"
            }
          ]
        }
      ],
      deal_discounts: [
        {
          id: "1e10b0db-b272-4f16-b959-ad2ed97264ff",
          name: "Techstars",
          amount: 120000,
          discount_code_id: "a218d586-f646-5a53-ac4f-d1ed679b6329"
        },
        {
          id: "e06caab8-4c76-4582-896e-974bab4e5eff",
          name: "Hiker's meals discount",
          amount: 30000,
          discount_code_id: null
        }
      ],
      priceTotal: 875000,
      discountsTotal: 150000,
      payTotal: 875000,
      dealDiscounts: [
        {
          id: "1e10b0db-b272-4f16-b959-ad2ed97264ff",
          name: "Techstars",
          amount: 120000,
          discount_code_id: "a218d586-f646-5a53-ac4f-d1ed679b6329"
        },
        {
          id: "e06caab8-4c76-4582-896e-974bab4e5eff",
          name: "Hiker's meals discount",
          amount: 30000,
          discount_code_id: null
        }
      ]
    },
    updatedAtHumanized: "7 days ago",
    author: {
      fullName: "Hiking fan"
    },
    billingPerson: {
      id: "4c87e4bb-2898-4a46-9d5b-36bada7e3c95",
      fullName: "Edward Abbey",
      firstName: "Edward ",
      lastName: "Abbey",
      email: "edabbey@moab.com"
    },
    organization: {
      id: "18bc13fa-0864-474e-9bfa-ba1839486c97",
      displayName: "Hiking Co",
      isSelfPetitioning: false,
      isNotYetEstablished: false
    },
    dealBeneficiaries: ["Some Dude", "Other Person", "Randy Marsh"],
    petitionBundles: [
      {
        id: "60f8c151-2704-44d4-ac4d-186f9c5b1619",
        additionalServiceIds: [
          "5e56a049-8419-4f6b-97f1-fd8a893b1614",
          "3ed926d8-b3aa-4cb6-bc31-856a45f864a8"
        ],
        finalPriceData: {
          base_fees: [
            {
              name: "Service Fee",
              amount: 800000
            }
          ],
          price_total: 1025000,
          additional_services: [
            {
              id: "5e56a049-8419-4f6b-97f1-fd8a893b1614",
              name: "Guaranteed approval",
              amount: 150000
            },
            {
              id: "3ed926d8-b3aa-4cb6-bc31-856a45f864a8",
              name: "Additional press",
              amount: 75000
            }
          ]
        },
        petition: {
          id: "c1344858-91b8-401d-8ade-510cecf18826",
          workflow: {
            id: "a2a0ed44-fbd6-425d-9bea-9b30328e3c7d",
            name: "E-2"
          },
          state: "pending",
          beneficiary: {
            id: "fba19ab0-7c4a-4bd1-920c-5625c253001e",
            firstName: "John",
            lastName: "Muir",
            email: "jmuir@hiker.com"
          },
          representative: {
            id: "fba19ab0-7c4a-4bd1-920c-5625c253001e",
            firstName: "John",
            lastName: "Muir",
            email: "jmuir@hiker.com"
          }
        }
      }
    ],
    dealDiscounts: [
      {
        id: "1e10b0db-b272-4f16-b959-ad2ed97264ff",
        amount: 120000,
        name: "Techstars",
        discountCodeId: "a218d586-f646-5a53-ac4f-d1ed679b6329"
      },
      {
        id: "e06caab8-4c76-4582-896e-974bab4e5eff",
        amount: 30000,
        name: "Hiker's meals discount",
        discountCodeId: null
      }
    ],
    installments: 6,
    archived: false
  }
];
