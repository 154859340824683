export const alphabeticalSort = (a, b, ascending) => {
  if (!a) {
    return -1;
  }
  if (!b) {
    return +1;
  }
  if (ascending) return a.localeCompare(b);
  return b.localeCompare(a);
};

export const chronologicalSort = (a, b, ascending) => {
  if (!ascending) {
    return new Date(a) - new Date(b);
  }
  return new Date(b) - new Date(a);
};

export const numericalSort = (a, b, ascending) => {
  if (!ascending) {
    return a - b;
  }
  return b - a;
};

export const booleanSort = (a, b, ascending) => {
  if (ascending) return a === b ? 0 : a ? -1 : 1;
  if (!ascending) return a === b ? 0 : a ? 1 : -1;
};
