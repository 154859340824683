// Utility methods for getting data from the DOM and into JSON for api calls

export const dataFromAttributeRows = attributeRows => {
  const data = {};

  // eslint-disable-next-line array-callback-return
  attributeRows.map(attribute => {
    const keyNode = attribute.getElementsByClassName("label")[0];
    const textNode = attribute.getElementsByClassName("value")[0];

    const key = keyNode ? keyNode.textContent : "";
    const value = textNode ? textNode.textContent : "";

    data[key] = value;
  });

  return data;
};
