export const mockNotesProps = {
  source: "Petition Creation",
  notable: {
    id: "aeaaa9de-21f5-470f-a283-a8e0db638d15"
  },
  notableType: "Petition"
};

export const mockNote = {
  id: "6cdbdb78-647e-45bb-906f-f59cd44b0e2f",
  author_id: "3849b33a-1caf-4310-9e27-7d7409dfb690",
  text:
    "## Markdown Note \n Amazing new note with pertinent info. \n - bulleted list\n - bullet 2",
  notable_id: "44f53b4b-5df3-47ea-bd18-ada35484d9ac",
  notable_type: "Petition",
  source: "Petition Creation",
  created_at: "2020-02-25T09:50:37.245-08:00",
  updated_at: "2020-02-25T09:52:20.687-08:00"
};

export const mockNote2 = {
  id: "123",
  author_id: "3849b33a-1caf-4310-9e27-7d7409dfb690",
  text: "# Wait, one more new note",
  notable_id: "44f53b4b-5df3-47ea-bd18-ada35484d9ac",
  notable_type: "Petition",
  source: "Petition Creation",
  created_at: "2020-02-25T09:50:37.245-08:00",
  updated_at: "2020-02-25T09:52:20.687-08:00"
};

export const mockNote3 = {
  id: "422225255",
  author_id: "3849b33a-1caf-4310-9e27-7d7409dfb690",
  text: "# Sweet new note",
  notable_id: "44f53b4b-5df3-47ea-bd18-ada35484d9ac",
  notable_type: "Petition",
  source: "Petition Creation",
  created_at: "2020-02-25T09:50:37.245-08:00",
  updated_at: "2020-02-25T09:52:20.687-08:00"
};

export const mockNotes = [
  {
    id: "6cdbdb78-647e-45bb-906f-f59cd4asdfdsf",
    author_id: "3849b33a-1caf-4310-9e27-7d7409dfb690",
    text: "Amazing new note with pertinent info.",
    notable_id: "44f53b4b-5df3-47ea-bd18-ada35484d9ac",
    notable_type: "Petition",
    source: "Petition Creation",
    created_at: "2020-02-25T09:50:37.245-08:00",
    updated_at: "2020-02-25T09:52:20.687-08:00"
  },
  {
    id: "6cdbdb78-647e-45bb-906f-assdfasfaf",
    author_id: "3849b33a-1caf-4310-95527-7d7409dfb690",
    text: "Other import note",
    notable_id: "44f53b4b-5df3-47ea-bd18-notARealNoteId",
    notable_type: "Petition",
    source: "Petition Creation",
    created_at: "2020-02-25T08:50:37.245-08:00",
    updated_at: "2020-02-25T09:52:20.687-08:00"
  },
  {
    id: "6cdbdb78-647e-45bb-906f-f59cd44b0e2f",
    author_id: "3849b33a-1caf-4310-9e27-7d5209dfb690",
    text: "Third and final note on this petition.",
    notable_id: "44f53b4b-5df3-47ea-bd18-fakeNoteId",
    notable_type: "Petition",
    source: "Petition Creation",
    created_at: "2020-02-24T09:50:37.245-08:00",
    updated_at: "2020-02-25T09:52:20.687-08:00"
  }
];
