/* eslint-disable no-plusplus */
/* eslint-disable no-sequences */
export const uniqueArrayByProp = (prop, array) => [
  ...array
    .reduce(
      (acc, item) => (item && item[prop] && acc.set(item[prop], item), acc), // using map (preserves ordering)
      new Map()
    )
    .values()
];

export const countOccurrences = (array, element) =>
  array.filter(x => x === element).length;
